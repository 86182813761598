body {
  margin: 0;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 10;
  display: flex;
  justify-content: center;
  width: 100%;
}

@font-face {
    font-family: 'Lemon Milk';
    src: url('./assets/fonts/LEMONMILK-Light.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family:'Lemon Milk Pro';
    src: url('./assets/fonts/Lemon\ Milk\ Pro\ Ultra\ Light.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

.form{
  margin: auto;
  .custom-textfield {
    width: 100%;
      margin: 12px;
      input {
        color: #fff !important;
        font-weight: 600; 
        font-size: 1.3rem;
      }
      textarea{
        color: #fff !important;
        font-weight: 600; 
        font-size: 1.3rem;
      }
    
      .MuiInputLabel-root {
        color:#B3B5B5; 
    
        &.Mui-focused {
          color: white; 
        }
      }
    
      .MuiInput-underline:before {
        border-bottom-color: #B3B5B5; 
      }
    
      .MuiInput-underline:after {
        border-bottom-color: white; 
      }
    
      .MuiFormHelperText-root {
        color: white; 
      }
    }
    
}