.project-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    gap: 30px;

    .MuiPaper-root {
        border-radius: 0%;
    }
}

#projects-button {
    border-color: black;
    font-family: "Lemon Milk";
    border-radius: 0%;
    display: flex;
    padding: 8px 0%;  
    justify-content: center;
    margin: 5% auto;

    &:hover {
        background-color: black;
        color: #fff;
    }
}

.project-card {
    position: relative;
    background-size: cover;
    background-position: center;
    height: 450px;
    color: #fff !important;
    overflow: hidden;
    transition: transform 0.3s ease-in-out !important;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        transition: background-color 0.3s ease-in-out;
        z-index: 0;
    }

    &:hover::before {
        background-color: rgba(0, 0, 0, 0); 
    }

    .MuiCardContent-root {
        z-index: 1; 
    }

    &:hover {
        transform: scale(1.03);
    }
}

