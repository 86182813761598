.drawer-content{
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
background-color:black;
.drawer-items{
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    flex-grow: 1;
    justify-content: start;
    .page-list{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
}
